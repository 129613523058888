<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <page-block class="vps-detail-view__block">
        <base-table :columns="tableHead" :list="paginList" class="tariffs-table__table">
          <tariffs-table-disk-row
            v-for="item in paginList"
            :key="item.id"
            :columns="tableHead"
            type="server"
            :item="item"
            @click-more="$emit('click-more', item)"
          />
        </base-table>
      </page-block>
    </transition>
  </div>
</template>

<script>
import TariffsTableDiskRow from '../pages/Main/components/TariffsTableDiskRow';
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import BaseTable from '@/components/Table/BaseTable';
export default {
  name: 'ServerDisk',
  components: {
    BaseTable,
    TariffsTableDiskRow,
    // MainCard,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '330px',
            maxWidth: '380px',
          },
        },
        // {
        //   key: 'boot',
        //   label: this.$t('tableHead.boot'),
        //   style: {
        //     width: '80px',
        //   },
        // },
        // {
        //   key: 'type',
        //   label: this.$t('tableHead.type'),
        //   style: {
        //     width: '80px',
        //   },
        // },
        {
          key: 'size',
          label: this.$t('tableHead.size'),
          style: {
            width: '80px',
            maxWidth: '124px',
          },
        },
        {
          key: 'attr',
          label: this.$t('tableHead.attr'),
          style: {
            width: '80px',
            maxWidth: '140px',
            // textAlign: 'right',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '54px',
            naxWidth: '124px',
          },
        },

        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
            maxWidth: '44px',
          },
        },
      ],
    };
  },
  computed: {
    status() {
      return this.tariff.status.code;
    },
    id() {
      return this.tariff.id;
    },
    paginList() {
      if (this.pagin.step === 'all') return this.disks;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.disks.slice(start, end);
    },
    disks() {
      return (
        this.$store.state.moduleStack.volumes
          .filter(i => i.attachments.length > 0)
          .filter(x => x.attachments[0].server_id === this.id) || []
      );
    },
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
      // this.$router.push({ name: 'vpsMain' });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память",
      "tableHead": {
        "name": "Имя диска",
        "type": "Тип",
        "size": "Размер",
        "boot": "Загрузочный",
        "attr": "Атрибуты",
        "created": "Создан",
        "config": "Конфигурация",
        "status": "Статус",
        "cost": "Цена",
        "menu": "Меню"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
